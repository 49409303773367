@import '../../styles/variables';
@import '../../styles/breakpoints';

.chatHistory {
  .loadingContainer {
    justify-content: center;
    padding: 8px;
    display: flex;
    align-items: center;
    z-index: 2;
    background-color: $loaderBackground;

    &.fullScreen {
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 3;
      height: 100%;
    }

    &.fullLoader {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      z-index: 3;
      background-color: $loaderBackground;
    }
  }

  .chatHistoryList {
    display: flex;
    list-style-type: none;
    padding: 21px 21px 50px;
    flex-direction: column;
    cursor: pointer;

    li {
      padding-bottom: 16px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $lightWhiteBorder;

      .imageContainer {
        margin-right: 10px;
        border-radius: 16px;
        flex-shrink: 0;
        overflow: hidden;
        width: 62px;
        height: 62px;
      }

      .chatDetail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        max-width: calc(100% - 70px);
        overflow: hidden;

        .userDetail {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .charName {
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            color: $white80;
            overflow: hidden;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            word-wrap: break-word;
            margin-right: 5px;
          }

          .time {
            font-size: 13px;
            font-weight: 400;
            line-height: 130%;
            color: $white50;
            flex-shrink: 0;
          }
        }
      }

      .lastMessage {
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        color: $white80;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }

      &:last-child {
        margin: 0;
        border: none;
      }

      &.active {
        background: linear-gradient(
            90deg,
            rgba(59, 110, 113, 0.3) 0%,
            rgba(62, 83, 137, 0.3) 50%,
            rgba(52, 46, 122, 0.3) 100%
          ),
          rgba(255, 255, 255, 0.1);
      }

      &:hover {
        background: $borderWhite;
      }
    }
  }

  &.desktopChats {
    padding: 0;

    .chatHistoryList {
      padding: 0;

      .imageContainer {
        margin-right: 8px;
        border-radius: 100%;
        flex-shrink: 0;
        overflow: hidden;
        width: 36px;
        height: 36px;
      }

      li {
        padding: 16px 24px;
        margin: 0;

        .chatDetail {
          max-width: unset;
        }

        &:last-child {
          padding: 16px 24px;
        }
      }
    }
  }
}
